 .App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.button {
  background-color: #DB7093;
  transition-duration: 0.4s;
  border-radius: 25px;
  font-size: 1rem;
  border: none;
  color: white;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.small-button {
  background-color: #DB7093;
  transition-duration: 0.4s;
  border-radius: 25px;
  font-size: 1rem;
  border: none;
  color: white;
  padding: 2px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

button.deleteGuest {
  background-color: grey;
  transition-duration: 0.4s;
  border-radius: 25px;
  font-size: 1rem;
  border: none;
  color: white;
  padding: 2px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.button:hover, .small-button:hover {
  background-color: #e7e7e7;
  color: black;
  border: 1px solid #DB7093;
}

.button:disabled {
  opacity: 0.25;
  pointer-events: none;
}

div.button-parent {
  margin: 0;
  text-align: right;
}

button.delete {
  display: inline-block;
  margin: 1px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: black;
  color: white;
}

button.delete:hover {
  background-color: white;
  color: black;
}

button.delete:hover, button.deleteGuest:hover {
  background-color: white;
  color: black;
}

ul {
  list-style-type: none;
}


.save-the-date .hover {
  opacity: 0.5;
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

img.hover {
  border-radius: 25px;
}

.save-the-date .root-link1 {
  margin: 0 auto;
  opacity: 1;
  width: 100%;
  position: fixed;
  bottom: 5rem;
  text-align: center;
}

.save-the-date h1 {
  font-family: 'Playball', cursive;
  text-align: center;
  font-size: 5rem;
  opacity: 0.85;
  text-shadow: 1px 1px white;
  line-height: 3rem;
}

.save-the-date h2, .rsvp h2 {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-size: 1.5rem;
  opacity: 0.85;
  text-shadow: 0.9px 0.9px white;
}

h3 {
  font-family: 'Playball', cursive;
  text-align: center;
  font-size: 2.5rem;
  opacity: 0.85;
  text-shadow: 0.9px 0.9px white;
}

h3.party {
  margin: 20px 0px auto;
  font-size: 2rem;
  line-height: 2rem;
}

.updateRsvpForm h3.party {
  font-size: 1.5rem;
  margin: 5px auto;
}

.updateRsvpForm input[type="checkbox"] {
  height: 1.5rem;
}

input[type="number"] {
  text-align: center;
  width: 10%;
}

.italicized {
  font-style: italic;
}

.bold {
  font-size: 1.1rem;
  font-weight: bold;
}

td, th {
  padding: 0px 10px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

td {
  height: 75px;
  vertical-align: middle;
}


td.eventsDateCol {
    min-width: 150px;
  }

tr {
  height: 200px;
}

tr:hover {
  background-color: #f5f5f5;
}

table.shuttleInfo {
  min-width: 300px;
}

table.mini {
  margin: 0 auto;
  width: 80%;
}

.mini td {
  height: auto;
}

tr.shuttleInfo {
  height: auto;
}

p.cursive {
  margin: 1rem 5rem;
  font-family: 'Poppins', sans-serif;
  font-style: italic;
  font-size: 1rem;
  text-shadow: 0.8px 0.8px  1px white;
}

a.navbar-items.active {
  pointer-events: none;
  font-weight: bold;
  opacity: 1;
  text-decoration: overline;
}

.nav {
  margin: 10px auto;
  width: 95%;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 1px 1px 30px 0px rgba(255, 255, 255, 0.9);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: space-around;
  padding: 10px;
  border-radius: 5px;
}

a:link.addToCal {
  font-size: 0.75rem;
  text-align: center;
}

.addToCal p {
  margin: 20px;
  border-style: solid;
  border-color: black;
  border-width: thin;
  border-radius: 5px;
  padding: 5px;
}

.addToCal p:hover {
  border-style: solid;
  border-color: white;
  border-width: thin;
  border-radius: 5px;
  padding: 5px;
  color: white;
  background-color: silver;
}

.navbar-items {
  opacity: 0.6;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  justify-content: space-between;
  margin: auto 0.5rem;
}

.text-center {
  text-align: center;
}

.navbar-items:hover {
  opacity: 1;
  transition: opacity 100ms linear;
  text-decoration: overline;
}

.hover:hover {
  opacity: 1;
  transition: opacity 500ms linear;
}

.root-link1:hover {
  opacity: 1;
  transition: opacity 500ms linear;
}

.root-link2:hover {
  opacity: 1;
  transition: opacity 500ms linear;
}

a:link,
a:visited {
  color: black;
  opacity: 0.6;
  text-decoration: none;
}

a:hover {
  opacity: 1;
  transition: opacity 500ms linear;
}

/* story page */
.photo-container {
  margin: 0 auto;
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  align-content: center;
  justify-content: space-around;
}

.photo-container span {
  padding: 1rem;
}

.rounded-container {
  width: 95%;
  max-width: 750px;
  margin: 1rem auto;
}

.inner-container {
  margin: 0 auto;
  width: 60%;
  padding: 10px;
}

.rounded-container-card {
  width: 75%;
  max-width: 500px;
  margin: 1rem auto;
  padding: 10px;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.5);
}

div.main {
  max-width: 100%;
  margin: 50px;
}

div.rounded-container {
  padding: 10px;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.5);
}

div.white-bubble {
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
}

input.text {
  min-width: 100px;
  max-width: 50%;
  pointer-events: none;
  border: none;
}

.rotateimg80 {
  -webkit-transform:rotate(80deg);
  -moz-transform: rotate(80deg);
  -ms-transform: rotate(80deg);
  -o-transform: rotate(80deg);
  transform: rotate(80deg);
}

.rotateimg15 {
  -webkit-transform:rotate(15deg);
  -moz-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  -o-transform: rotate(15deg);
  transform: rotate(15deg);
}

.rotateimg-15 {
  -webkit-transform:rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  transform: rotate(-15deg);
}

.story {
  line-height: 1.5rem;
}

/* accomodations */
label {
  font-family: 'Playball', cursive;
}

.party p, .readable {
  font-family: 'Poppins', sans-serif;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

/* loading */
.lds-heart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #fff;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: #fff;
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

/* general */

input {
  height: 2rem;
  width: 50%;
}

input.button {
  height: 2.5rem;
  width: 20%;
}

footer {
  font-family: 'Cedarville Cursive', cursive;
  font-size: 0.75rem;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  opacity: 0.1;
}

iframe {
  min-height: 500px;
}

@media only screen and (max-width: 600px) {

  .nav {
    background-color: rgba(255, 255, 255, 1);
    margin: 10px auto;
    width: 90%;
  }

  a:link,
  a:visited {
    color: black;
    opacity: 1;
    text-decoration: none;
  }

  .save-the-date h1 {
    line-height: 3rem;
    opacity: 1;
  }

  .save-the-date .root-link1 {
    opacity: 1;
    width: 100%;
    position: fixed;
    bottom: 3rem;
    text-align: center;
  }

  .photo-container {
    margin: 0 auto;
    display: flex;
    flex-wrap: no-wrap;
    align-items: center;
    align-content: center;
    justify-content: space-around;
  }

  .photo-container img {
    max-width: 120%;
    height: auto;
  }

  .inner-container {
    margin: 0 auto;
    width: auto;
  }

  input {
    width: 80%;
  }

  input.button {
    height: 2.5rem;
    width: 50%;
  }

  p.cursive {
    margin: 0rem 1rem;
  }

  td, th {
    padding: 10px 5px 10px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  td {
    vertical-align: middle;
  }

  tr {
    height: 150px;
    background-color: #f5f5f5;
  }
}
